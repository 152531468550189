/**
 * this funcitons takes feature and checks if its in row
 * returns boolean
 */
export const listRepFromFilterList = (rowStrRep, filterLst) => {
  if (!rowStrRep || filterLst.length === 0) {
    return true;
  }
  if (rowStrRep.length === 2) {
    return false;
  }

  const hashtagsList = rowStrRep.split(",");
  console.log(hashtagsList);
  if (hashtagsList === rowStrRep) {
    return filterLst.some((hashtag) => hashtagsList.includes(hashtag));
  } else {
    for (const hashtag of hashtagsList) {
      for (const filter of filterLst) {
        if (hashtag.includes(filter)) {
          return true;
        }
      }
    }
    return false;
  }
};
