// utils/loadData.js
import Papa from "papaparse";

export const loadCsvFile = (csvFilePath) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csvFilePath, {
      download: true,
      header: true,
      complete: function (results) {
        // TODO
        resolve(results.data); // Return the parsed data
        // resolve(results.data.slice(0, 100)); // Return the parsed data
      },
      error: function (err) {
        reject(err); // Reject on error
      },
    });
  });
};
export const csvFileDownload = (fileUrl) => {
  console.log("Downloading File");

  try {
    // Create a temporary link element for downloading the CSV file
    const a = document.createElement("a");
    a.href = fileUrl; // Set the href to the CSV file URL
    a.download = fileUrl.split("/").pop(); // Use the file name from the URL

    // Append the anchor to the body (needed for Firefox)
    document.body.appendChild(a);

    // Trigger the download by simulating a click on the anchor
    a.click();

    // Clean up by removing the anchor from the document
    a.remove();
  } catch (error) {
    // Log any errors that occur during the process
    console.error("Error downloading the CSV file:", error);
  }
};
// export const csvVariableDownload = (csvData) => {
//     console.log("Downloading Var");
//     try {
//         // Create a Blob from the CSV data
//         const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

//         // Create a temporary link element for downloading the CSV file
//         const a = document.createElement('a');
//         const url = URL.createObjectURL(blob); // Generate a URL for the Blob
//         a.href = url;
//         a.download = 'data.csv'; // Set a default name for the downloaded file

//         // Append the anchor to the body (needed for Firefox)
//         document.body.appendChild(a);

//         // Trigger the download by simulating a click on the anchor
//         a.click();

//         // Clean up by revoking the object URL and removing the anchor
//         URL.revokeObjectURL(url);
//         a.remove();
//     } catch (error) {
//         // Log any errors that occur during the process
//         console.error("Error downloading the CSV file:", error);
//     }
// };

export const csvVariableDownload = (csvData) => {
  console.log("Downloading filtered CSV");

  try {
    console.log(csvData);
    // Convert JSON data to CSV format using Papa.unparse
    const csvString = Papa.unparse(csvData);

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    // Create a temporary link element for downloading the CSV file
    const a = document.createElement("a");
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = "filtered_data.csv"; // Set a default name for the downloaded file

    // Append and trigger download, then clean up
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
  } catch (error) {
    console.error("Error downloading the CSV file:", error);
  }
};
