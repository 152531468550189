import React, { useState } from "react";
import TwitterDashboard from "../components/dashboards/TwitterDashboard.js";
import TelegramDashboard from "../components/dashboards/TelegramDashboard.js";
import "./css/ResearchScreen.css";
function ResearchScreen() {
  const [selectedDashboard, setSelectedDashboard] = useState(null);

  const handleButtonClick = (dashboard) => {
    setSelectedDashboard(dashboard);
  };
  const universitiesLogos = `${process.env.PUBLIC_URL}/universitiesLogos.png`;

  return (
    <div className="research-screen">
      {/* Part 1: Title and explanation */}
      <div className="section title-section">
        <h1>Welcome to the Research Platform</h1>
        <p>
          The data includes various channels covering politics, foreign
          administration, and newspapers, specifically focusing on the war that
          began on October 7th. You can filter the data according to your
          preferences.
        </p>
      </div>

      {/* Part 2: Current data and dashboards */}
      <div className="section data-section">
        <h2>Select a Dashboard</h2>
        <div className="button-group">
          <button
            className={`button ${
              selectedDashboard === "twitter" ? "selected" : ""
            }`}
            onClick={() => handleButtonClick("twitter")}
          >
            Twitter
          </button>
          <button
            className={`button ${
              selectedDashboard === "telegram" ? "selected" : ""
            }`}
            onClick={() => handleButtonClick("telegram")}
          >
            Telegram
          </button>
        </div>
        <div className="dashboard-content">
          {selectedDashboard === "twitter" && <TwitterDashboard />}
          {selectedDashboard === "telegram" && <TelegramDashboard />}
        </div>
      </div>

      {/* Part 3: Contact Section */}
      <div className="section contact-section">
        <h2>Contact Us</h2>
        <p>Prof Ilan manor: manor.ilan@gmail.com</p>
        <p>Prof Orly manor: orlyma@ekmd.huji.ac.il</p>
      </div>

      {/* Part 4: Logos */}
      <div className="section logo-section">
        <div className="logo-container">
          <img src={universitiesLogos} alt="Logo 1" className="logo" />
        </div>
      </div>
    </div>
  );
}

export default ResearchScreen;
