import React, { useState } from "react";
import "./css/LoginScreen.css"; // Import the CSS file

function LoginScreen({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const universitiesLogos = `${process.env.PUBLIC_URL}/universitiesLogos.png`;

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        "https://data-bank-israel-war-app.vercel.app/login/ilan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        }
      );

      if (!response.ok) {
        throw new Error("Invalid username or password");
      }
      onLogin(true);
      setUsername("");
      setPassword("");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-screen">
      {/* Top Section */}
      <div className="top-section">
        <p>Welcome to the Data Bank Website! Please login to continue.</p>
      </div>

      {/* Center Section */}
      <div className="center-section">
        <div className="login-container">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <button type="submit" disabled={loading}>
              {loading ? "Logging in..." : "Login"}
            </button>
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="bottom-section">
        <div className="logo-container">
          <img src={universitiesLogos} alt="Logo 1" className="logo" />
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
